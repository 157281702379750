















































































import { Component, Vue, Prop } from 'vue-property-decorator';
import { Listing } from 'client-website-ts-library/types/Listing';
import { API } from 'client-website-ts-library/services';

@Component
export default class ListingCard extends Vue {
  @Prop()
  private readonly listing!: Listing;

  @Prop()
  private readonly showInspectionTime!: boolean;

  get isCurrent() {
    return this.listing.Status === 'Current' || this.listing.Status === 'Under Contract';
  }

  get isNew(): boolean {
    const newThreshold = (new Date().getTime()) - 86400 * 7 * 1000;

    return new Date(this.listing.CreateDate).getTime() >= newThreshold;
  }

  get formattedAddressLine1(): string {
    const parts = [];

    if (this.listing.Address.UnitNumber) {
      parts.push(`${this.listing.Address.UnitNumber}/${this.listing.Address.StreetNumber}`);
    } else {
      parts.push(this.listing.Address.StreetNumber);
    }

    parts.push(this.listing.Address.Address);

    return parts.join(' ');
  }

  get formattedAddressLine2(): string {
    return `${this.listing.Address.Suburb}, ${this.listing.Address.State} ${this.listing.Address.PostCode}`;
  }

  get formattedDateAvailable(): string {
    if (!this.listing.DateAvailable) return 'now';

    const now = new Date();
    const dateAvailable = new Date(this.listing.DateAvailable);

    if (dateAvailable <= now) return 'now';

    return `${dateAvailable.getDate()}/${(dateAvailable.getMonth() + 1)}/${dateAvailable.getFullYear().toString().substr(2, 2)}`;
  }

  get openHomeRelative(): string {
    const nextOpenHome = this.listing.InspectionTimes[0].StartDateInfo;

    if (nextOpenHome.IsToday) return 'Today';
    if (nextOpenHome.IsTomorrow) return 'Tomorrow';

    return '';
  }

  get inspectionTime(): string {
    if (this.listing.InspectionTimes.length === 0) return '';

    return `${this.listing.InspectionTimes[0].StartDateInfo.Time.LongName} - ${this.listing.InspectionTimes[0].EndDateInfo.Time.LongName}`;
  }

  handleIREClicked() {
    API.Listings.GetInspectRealEstateData(this.listing.Id).then((ireData) => {
      window.open(ireData.Url, '_blank');
    }).catch(() => {
      alert('There was an error processing your request. Please try again later.');
    });
  }
}
